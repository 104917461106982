import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { BiLogoFacebook } from "react-icons/bi";
import { CiMenuBurger } from "react-icons/ci";
import { IoMdClose } from "react-icons/io";
import { FaPizzaSlice } from "react-icons/fa";
import { MdOutlinePhoneIphone } from "react-icons/md";
import { IoMdInformationCircleOutline } from "react-icons/io";

const Navbar = () => {
  const [menuBurger, setMenuBurger] = useState(false);

  const OpenFacebook = () => {
    const facebookPageUrl =
      "https://www.facebook.com/profile.php?id=61555503459228";
    window.open(facebookPageUrl, "_blank");
  };

  const openMenuBurger = () => {
    setMenuBurger(!menuBurger);
    setTimeout(() => {
      const menu = document.querySelector(".navigation-burger");
      menu.classList.add("opening");
    }, 1);
  };

  const closeMenuBurger = () => {
    const menu = document.querySelector(".navigation-burger");
    menu.classList.remove("opening");
    menu.classList.add("closing");

    setTimeout(() => {
      menu.classList.remove("closing");
      setMenuBurger(!menuBurger);
    }, 500);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="burger-icon">
        <CiMenuBurger
          className="burger-icon"
          onClick={() => {
            openMenuBurger();
          }}
        />
      </div>
      {menuBurger ? (
        <div className="navigation-burger">
          <nav
            onClick={() => {
              setMenuBurger(!menuBurger);
            }}
          >
            <div className="navigation-burger-link">
              <FaPizzaSlice onClick={() => scrollToSection("menu")} />
              <span onClick={() => scrollToSection("menu")}>Menu</span>
            </div>
            <div className="navigation-burger-link">
              <MdOutlinePhoneIphone
                onClick={() => scrollToSection("contact")}
              />
              <span onClick={() => scrollToSection("contact")}>Contact</span>
            </div>
            <div className="navigation-burger-link">
              <IoMdInformationCircleOutline
                onClick={() => scrollToSection("about")}
              />
              <span onClick={() => scrollToSection("about")}>A propos</span>
            </div>
          </nav>
          <div className="navigation-icons">
            <BiLogoFacebook onClick={OpenFacebook} />
            <IoMdClose
              onClick={() => {
                closeMenuBurger();
              }}
            />
          </div>
        </div>
      ) : (
        <div className="navigation-menu">
          <nav>
            <div className="navigation-link">
              <span onClick={() => scrollToSection("menu")}>Menu</span>
            </div>
            <div className="navigation-link">
              <span onClick={() => scrollToSection("contact")}>Contact</span>
            </div>
            <div className="navigation-link">
              <span onClick={() => scrollToSection("about")}>A propos</span>
            </div>
            <div className="navigation-icons">
              <BiLogoFacebook onClick={OpenFacebook} />
            </div>
          </nav>
        </div>
      )}
    </>
  );
};

export default Navbar;
