import React from 'react';
import Header from './components/Header/Header';
import './App.css';
import Datas from './data/menu.json';
import Footer from './components/Footer/Footer';
import ScrollToTopButton from './components/ScrollToTopButton/ScrollToTopButton';
import { Carousel } from 'react-responsive-carousel';

const menu = Datas.menu;

const App = () => {
  function onPhoneClick() {
    window.open(`tel:+33490416852`);
  }

  return (
    <>
      <Header />
      <div className="wrapper">
        <section className="title-section">
          <div className="title">
            <h1>Pizzas artisanales et ingrédients cuisinés</h1>
            <div>
              <div>
                <img
                  src="./medias/flag.png"
                  alt="Le meilleur de la pizza italienne"
                />
              </div>
            </div>
            <p>
              Bienvenue Au Pizzanyl à Valréas : dégustez des pizzas authentiques
              avec pâte maison et ingrédients frais!
            </p>
            <button
              className="btn"
              onClick={() => {
                onPhoneClick();
              }}
            >
              Appeler
            </button>
          </div>
          <div className="banner">
            <img src="./medias/tomato.png" alt="Tomate fraiche et cuisinée" />
          </div>
        </section>
        <section className="infos">
          <div>
            <p>
              Créez la pizza de votre choix en sélectionant 3 ingrédients de la
              carte !
            </p>
            <p>
              <span className="color-red">1€</span> par ingrédient
              supplémentaire au delà des 3 précédemment choisis.
            </p>
          </div>
          <div>
            <p>
              Vous préférez la base crème plutôt que tomate et inversement ?
              Aucun souci, n'hésitez pas à demander !
            </p>
          </div>
          <div>
            <p>
              Pour plus de convivialité entres amis, pour 4 pizzas achetées une
              bouteille de rosé ou soda offerte.
            </p>
          </div>

          <div>
            <p>Ne ratez pas notre pizza du mois !</p>
            <p>
              A chaque mois sa pizza, 12 créations dans l'année à emporter pour
              le plaisir de vos papilles.
            </p>
          </div>
        </section>
        <section id="menu">
          <div className="menu-bg">
            <img
              src="./medias/menu-bg.png"
              alt="Selectionnez le menu et faites votre choix"
            />
          </div>
          <div className="menu">
            <h2>Les classiques</h2>
            <ul className="pizzas-list">
              {menu.classics.map((pizza, index) => (
                <li key={index} className="pizza-row">
                  <div className="pizza-description">
                    <span className="color-green">{pizza.name}</span> :{' '}
                    {pizza.ingredients}
                  </div>
                  <span className="pizza-price">{pizza.price}€</span>
                </li>
              ))}
            </ul>
            <h2>Les spéciales</h2>
            <ul className="pizzas-list">
              {menu.specials.map((pizza, index) => (
                <li key={index} className="pizza-row">
                  <div className="pizza-description">
                    <span className="color-green">{pizza.name}</span> :{' '}
                    {pizza.ingredients}
                  </div>
                  <span className="pizza-price">{pizza.price}€</span>
                </li>
              ))}
            </ul>
            <button
              className="btn"
              onClick={() => {
                onPhoneClick();
              }}
            >
              Appeler
            </button>
          </div>
        </section>
        <section id="about">
          <div className="padding-x">
            <h3>
              Bienvenue dans mon univers de saveurs : Yann, Pizzaiolo passionné
            </h3>
            <p>
              Bonjour, je m'appelle Yann et je suis le pizzaiolo passionné
              derrière chaque délicieuse création que vous dégustez chez
              Pizzanyl. Depuis mon enfance, la cuisine est ma passion, mais
              c'est la pizza qui occupe une place particulière dans mon cœur
              depuis plus de 10 ans.
              <br />
              <br /> Chez Pizzanyl, nous mettons l'accent sur l'authenticité et
              la fraîcheur. Chaque jour, je m'engage à préparer avec soin une
              pâte maison, car je crois que la qualité des ingrédients se
              reflète dans chaque bouchée. Mon objectif est de vous offrir une
              expérience gastronomique exceptionnelle à travers des saveurs
              simples et authentiques.
              <br />
              <br /> Mon amour pour la cuisine et la pizza se traduit dans
              chaque recette que je crée. Choisir les meilleurs ingrédients est
              pour moi une évidence, car je crois que la passion que je mets
              dans chaque pizza se transmet à chaque client. Chez Pizzanyl,
              chaque pizza est une création unique, résultat de mon dévouement
              et de ma quête constante de l'excellence.
              <br />
              <br /> Venez découvrir l'art de la pizza chez Pizzanyl, où chaque
              morceau raconte mon histoire culinaire. Je suis impatient de vous
              accueillir dans mon univers, où la passion et la fraîcheur se
              marient pour créer des moments gustatifs mémorables.
              <br />
              <br />
            </p>
            <p className="color-green signature">
              Yann, Pizzaiolo chez Pizzanyl
            </p>
          </div>
        </section>
        <div className="gallery">
          <div className="gallery-img">
            <img src="./medias/tomato2.png" alt="Ingrédients frais" />
          </div>
          <div className="gallery-img">
            <img
              src="./medias/spices.png"
              alt="Epices savoureuse pour mes pizzas"
            />
          </div>
          <div className="gallery-img">
            <img src="./medias/oil.png" alt="Huile d'olive de nos régions" />
          </div>
          <div className="gallery-img">
            <img src="./medias/flour.png" alt="Farine de qualité" />
          </div>
        </div>
        <section id="contact">
          <div className="padding-x">
            <h3>Contact et informations</h3>
            <p>Ouvert du mardi au dimanche</p>
            <p
              onClick={() => {
                onPhoneClick();
              }}
            >
              04 90 41 68 52
            </p>
            <p>16 Cours du berteuil 84600 Valréas</p>
            <p style={{ color: '#169a5a', fontWeight: 'bold' }}>
              Octobre à juin :
            </p>
            <p style={{ color: '#169a5a', fontWeight: 'bold' }}>18h à 21h</p>
            <p style={{ color: '#1652de', fontWeight: 'bold' }}>
              Juillet à septembre :
            </p>
            <p style={{ color: '#1652de', fontWeight: 'bold' }}>
              17h45 à 21h30
            </p>
            <div>
              <p>Paiement acceptés</p>
              <ul>
                <li>CB à partir de 12€</li>
                <li>Espèces</li>
              </ul>
            </div>
            <p>
              Commande possible à partir de{' '}
              <span className="color-red">17h30</span>
            </p>
            <button
              className="btn"
              onClick={() => {
                onPhoneClick();
              }}
            >
              Appeler
            </button>
          </div>
        </section>
      </div>
      <Footer />
      <ScrollToTopButton />
    </>
  );
};

export default App;
