import React from "react";
import "./Footer.css";

const Footer = () => {
  const handleRedirectClick = () => {
    window.open(
      "https://docs.google.com/document/d/1UnFE5n_oxWJ6qkKkQxuk-N312zu4zkEXaoeBK3nWCmI/edit?usp=sharing",
      "_blank"
    );
  };
  return (
    <footer>
      <div className="footer-txt">
        <p
          onClick={() => {
            window.open("https://imbertf.fr/", "_blank");
          }}
        >
          Site préparé aux petits oignons par ©️copyright -{" "}
          <span className="color-green">imbertf</span> - 2024
        </p>
        <p
          onClick={() => {
            handleRedirectClick();
          }}
        >
          mentions légales
        </p>
      </div>
    </footer>
  );
};

export default Footer;
